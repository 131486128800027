const normalizeSrc = (src: string | unknown) => {
  if (typeof src === 'string') return src[0] === '/' ? src.slice(1) : src
  return src
}

export default function cdnImageURL({
  src,
  width,
  quality,
  height,
}: {
  src: string
  width?: number
  height?: number
  quality?: number
}) {
  if (src == null) return ''

  if (src?.includes('.svg')) {
    // Cloudflare Image service doesn't support SVGs
    return src
  }

  // Rewrite the src to use the Cloudflare worker
  if (src.startsWith('https://i.travelapi')) {
    const url = new URL(src)
    url.hostname = 'img.airheart.com'
    src = url.toString()
  } else if (src.startsWith('/')) {
    if (import.meta.env.DEV) {
      return src
    } else {
      const url = new URL(src, 'https://cocorde.pages.dev')
      src = url.toString()
    }
  }

  const params = ['gravity=auto', 'fit=cover', 'metadata=none', 'format=auto']
  if (quality) params.push(`quality=${quality}`)
  if (height) params.push(`height=${height}`)
  if (width) params.push(`width=${width}`)

  const paramsString = params.join(',')

  if (typeof window !== 'undefined') {
    if (src.startsWith('/') && window.location.origin != 'https://airheart.com') {
      src = `${window.location.origin}${src}`
    }
  }

  return `https://airheart.com/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

export function cdnSrcSet(sizes: [number, number][], src: string) {
  if (src == null) return ''
  return sizes
    .map((size) => `${cdnImageURL({ src, width: size[0] })} media(max-width:${size[1]}px)`)
    .join(', ')
}

export function cdnSrcSetHiDPI(src: string, width: number, height?: number) {
  if (src == null) return ''

  if (width > 750) {
    return cdnImageURL({ src, width, height })
  }

  let opts = { src, width: width * 2, height: undefined, quality: 75 }
  if (height) opts = { ...opts, height: height * 2 }
  return [src, `${cdnImageURL(opts)} 2x`].join(', ')
}
